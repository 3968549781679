import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'Overview',
        items: [{ title: 'Dashboard', path: paths.clinic_dashboard.root, icon: ICONS.dashboard }],
      },

      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'Settings',
        items: [
          {
            title: 'Business Profile',
            path: paths.clinic_dashboard.business_profile,
            icon: ICONS.dashboard,
          },
        ],
      },
      {
        subheader: 'Packages',
        items: [
          {
            title: 'Manage Packages',
            path: paths.clinic_dashboard.packages,
            icon: ICONS.dashboard,
          },
        ],
      },
      {
        subheader: 'Doctors',
        items: [
          {
            title: 'Manage Doctors',
            path: paths.clinic_dashboard.doctors,
            icon: ICONS.dashboard,
          },
        ],
      },
    ],
    []
  );

  return data;
}

export function useBottomNavData() {
  const data = useMemo(
    () => [
      { title: 'Home', path: paths.clinic_dashboard.root, icon: <Iconify icon="tabler:home" /> },
      {
        title: 'Bookings',
        path: paths.clinic_dashboard.root,
        icon: <Iconify icon="tabler:calendar-filled" />,
      },
      {
        title: 'Packages',
        path: paths.clinic_dashboard.packages,
        icon: <Iconify icon="tabler:brand-youtube" />,
      },
      {
        title: 'Services',
        path: paths.clinic_dashboard.root,
        icon: <Iconify icon="tabler:brand-hipchat" />,
      },
      {
        title: 'Profile',
        path: paths.clinic_dashboard.business_profile,
        icon: <Iconify icon="tabler:user" />,
      },
    ],
    []
  );

  return data;
}
